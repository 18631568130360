.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.21);
  backdrop-filter: blur(15px);
  display: flex;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  overflow: auto;
  background: none;
  outline: none;
  padding: 20px;
  padding: 0;
  margin: auto;
  min-width: 400px;
  border-radius: 15px;
}
